import React, { useEffect, useState } from "react";
import Select from "react-select";
import config from "./config";
import { loadModules } from "esri-loader";
import "./App.css";
//import Axios from "axios";

function compare(a, b) {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  // a must be equal to b
  return 0;
}

const { hospitalConfig, stateBenchmarkConfig } = config;

// 1. one config obj
// 2. gets states from fs
// 3. Get Distinct Hospital and states and in dropdown
// 4. first state then hospital
// no all hospitals

function App() {
  // Access High Charts
  const Highcharts = window.Highcharts;

  let [paramState, setParamState] = useState("");
  let [paramHos, setParamHos] = useState("");

  const configVar = config.hospitalConfig.barVar;
  let [statsKey, setStatsKey] = useState({
    value: configVar,
    label: configVar,
  });

  function onSetStatsKey(value) {
    setStatsKey(value);
    queryGraphData(value);
  }

  // Get the graph data and rerender on button click
  function queryGraphData(statsKey) {
    loadModules(["esri/layers/FeatureLayer", "esri/identity/IdentityManager"])
      .then(([FeatureLayer, IdentityManager]) => {
        // ~~~~~~~~~~ SINGLE HOSPITAL ~~~~~~~~~~~~~~~~~~~~~~

        const hospitalLayer = new FeatureLayer({
          url: config.hospitalLayerURL,
        });

        const stateBenchLayer = new FeatureLayer({
          url: config.benchmarkLayerURL,
        });

        // Hospital Benchmark Data

        let query = hospitalLayer.createQuery();
        query.where = `state = '${paramState}' AND provider = '${paramHos}'`;
        query.outFields = hospitalConfig.outFields;
        query.returnGeometry = false;

        let hospitalProm = new Promise((resolve, reject) => {
          hospitalLayer
            .queryFeatures(query)
            .then((res) => {
              const hospitalTitle = res.features[0].attributes.hospital;

              let hosBenchmarkArr = [];
              res.features.forEach((feature) => {
                //extract actual data from hospital.
                let hosBenchmark = feature.attributes;
                hosBenchmarkArr.push(hosBenchmark);
              });

              hosBenchmarkArr = hosBenchmarkArr.sort((a, b) => {
                a = a.year;
                b = b.year;
                return compare(a, b);
              });

              let hosBenchmarkArrFull = hosBenchmarkArr.map((hosBenchmark) => {
                let arr = [];
                config.test[statsKey.value].forEach((elem) => {
                  let { value, label } = elem;
                  let obj = { label };
                  obj.value = hosBenchmark[value];
                  arr.push(obj);
                });
                return arr;
              });
              //     console.log(hosBenchmarkArrFull);

              hosBenchmarkArr = hosBenchmarkArr.map(
                (hosBenchmark) => hosBenchmark[statsKey.value]
              );

              resolve([hosBenchmarkArr, hosBenchmarkArrFull, hospitalTitle]);
            })

            .catch((error) => {
              console.log("Error in query:", error);
              reject(error);
            });
        });

        // ~~~~~~~~~~~~~~~ STATE WIDE BENCHMARKS ~~~~~~~~~~~~~~~~~~~~~~

        let query2 = stateBenchLayer.createQuery();
        //  query2.where = `state = '${state.value}'`;
        query2.where = `state = '${paramState}'`;
        query2.returnGeometry = false;
        query2.orderByFields = stateBenchmarkConfig.orderByFields;
        query2.outStatistics = [
          {
            statisticType: stateBenchmarkConfig.operation,
            onStatisticField: statsKey.value,
            outStatisticFieldName: "stats_var",
          },
        ];
        //add to config
        query2.groupByFieldsForStatistics =
          stateBenchmarkConfig.groupByFieldsForStatistics;

        let stateBenchProm = new Promise((resolve, reject) => {
          stateBenchLayer
            .queryFeatures(query2)
            .then((res) => {
              let groups = res.features;

              let hospitalObj = {};
              groups.forEach((hospital) => {
                //extract actual data from hospital.
                hospital = hospital.attributes;

                //config
                // if data doesn't exist on obj , add it
                if (!hospitalObj[hospital[stateBenchmarkConfig.objField]]) {
                  hospitalObj[hospital[stateBenchmarkConfig.objField]] = [];
                }

                // Make a year - value pair from hospital.
                //config
                let tempObj = {};
                tempObj[hospital.year] = hospital["stats_var"];
                hospitalObj[hospital[stateBenchmarkConfig.objField]].push(
                  tempObj
                );
              });

              // Once data is collected, ensure it is sorted by year order
              let mapKeys = Object.keys(hospitalObj);
              let returnHospitals = [];
              hospitalObj.keys = mapKeys;
              mapKeys.forEach((mapkey) => {
                let tempArr = hospitalObj[mapkey];
                tempArr = tempArr.sort((a, b) => {
                  let aKey = Object.keys(a)[0];
                  let bKey = Object.keys(b)[0];
                  return compare(aKey, bKey);
                });

                // insert arr into hospital obj
                hospitalObj[mapkey] = [...tempArr];

                // Also format the data to go into the chart.
                let tempArr2 = tempArr.map((elem) => Object.values(elem)[0]);
                returnHospitals.push({
                  type: "line",
                  name: mapkey,
                  data: tempArr2,
                });
              });
              resolve(returnHospitals);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });

        Promise.all([stateBenchProm, hospitalProm])
          .then((res) => {
            let dataObj = {
              benchmark: res[0],
              hospitals: res[1][0],
              hospitalsFull: res[1][1],
            };

            Highcharts.chart("container", {
              title: {
                text: res[1][2],
              },

              xAxis: {
                categories: [
                  "2011",
                  "2012",
                  "2013",
                  "2014",
                  "2015",
                  "2016",
                  "2017",
                  "2018",
                  "2019",
                  "2020"
                ],
                title: {
                  text: "Year",
                },
              },
              yAxis: {
                title: {
                  text: "Total Margin",
                },
              },
              legend: {
                layout: "vertical",
                align: "right",
                verticalAlign: "middle",
              },
              labels: {
                items: [
                  {
                    html: "",
                    style: {
                      left: "50px",
                      top: "18px",
                      color:
                        // theme
                        (Highcharts.defaultOptions.title.style &&
                          Highcharts.defaultOptions.title.style.color) ||
                        "black",
                    },
                  },
                ],
              },
              series: [
                {
                  type: "column",
                  name: "Benchmark",
                  data: dataObj.hospitals,
                  marker: {
                    lineWidth: 2,
                    lineColor: Highcharts.getOptions().colors[3],
                    fillColor: "white",
                  },
                  tooltip: {
                    useHTML: true,
                    pointFormatter: function (e) {
                      const { index } = this;
                      let string = "<div>";
                      dataObj.hospitalsFull[index].forEach((elem) => {
                        string = string.concat(
                          `<br> <b> ${elem.label}</b>: ${
                            elem.value ? elem.value : "No Data"
                          }</span> </br>`
                        );
                      });
                      string = string.concat("</div>");
                      return string;
                    },
                  },
                },

                ...dataObj.benchmark,
              ],
            });
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log("Error in esri loader:", error);
      });
  }

  useEffect(() => {
    if (!paramHos && !paramState) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const state = urlParams.get("state");
      const hospital = urlParams.get("hospital");
      setParamHos(hospital);
      setParamState(state);
    } else {
      queryGraphData(statsKey);
    }
  }, [paramState, paramHos]);

  return (
    <div className="App">
      <div className="gm-menu">
        <label style={{ display: "flex", alignItems: "center" }}>
          {" "}
          Select a variable:{" "}
        </label>
        <Select
          className="gm-select gm-statsOption-select"
          value={statsKey}
          options={config.statsOptions}
          onChange={onSetStatsKey}
        />
      </div>

      <div id="container"></div>
    </div>
  );
}

export default App;
