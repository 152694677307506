export default {
  hospitalLayerURL:
    "https://utility.arcgis.com/usrsvcs/servers/5ccbaf086b6641cc9e888145c58546b9/rest/services/US_HOSP_MASTER2/FeatureServer",
  benchmarkLayerURL:
    "https://utility.arcgis.com/usrsvcs/servers/404fb4c7847b472cac2ba8e31231b238/rest/services/BENCHMARKS_MASTER/FeatureServer",
  token:
    "O4d5_2l641kjbKKcGoP27PSxqtdQWGcFnNnx7_LBm0iHPyOGOv8V9Z5YFqcsm-NRembNObKGaMMx3J8zXdAvIjimQUOrY1WqvBn3svMy8QUggxW2UbCkqVDkIzXdZKROCB9U4tMZdrfXK_C3J4M3QzHiIfYnCGObBwI8_gDoLB3ZeIaUBVJVBWCqZb1cXDemYXPt-xemLMA7sO0V1AhilqvJYB6dkEeYPwJEnkfwhKo.",
  hospitalConfig: {
    state: "TX",
    title: "MITCHELL CNTY HSP DIST CO CITY",
    barVar: "Total_Margin",
    outFields: ["*"],
  },
  stateBenchmarkConfig: {
    state: "TX",
    orderByFields: "year",
    operation: "avg",
    operationVar: "Total_Margin",
    groupByFieldsForStatistics: ["year", "Group_"],
    objField: "Group_",
  },
  server_url: 'http://localhost:3001',
  //server_url: 'app.torchhub.org:3001'
  test: {
    Total_Margin: [
      { value: "year", label: "year" },
      { value: "Total_Margin", label: "Total_Margin" },
      { value: "F51", label: "Schedule G3 Line 029 Net income" },
      {
        value: "F29",
        label: "Schedule G3 Line 003 Net patient service revenue",
      },
      { value: "F50", label: "Schedule G3 Line 025 Total other income" },
    ],

    Cash_Flow_Margin: [
      { value: "year", label: "year" },
      { value: "Cash_Flow_Margin", label: "Cash_Flow_Margin" },
      { value: "F51", label: "Schedule G3 Line 029 Net income" },
      { value: "F31", label: "Schedule G3 Line 006 Contributions" },
      { value: "F48", label: "Schedule G3 Line 023 Government appropriations" },
      {
        value: "F14",
        label:
          "Schedule A Column 002 Line 001 Capital Related Costs- Building and Fixtures",
      },
      {
        value: "F15",
        label:
          "Schedule A Column 002 Line 002 Capital Related Costs- Movable Equipment",
      },
      {
        value: "F16",
        label: "Schedule A Column 002 Line 003 Other Capital Related Costs",
      },
      {
        value: "F11",
        label: "Schedule A Column 002 Line 113 Interest expense",
      },
      {
        value: "F29",
        label: "Schedule G3 Line 003 Net patient service revenue",
      },
      { value: "F50", label: "Schedule G3 Line 025 Total other income" },
      { value: "F32", label: "Schedule G3 Line 007 Investment income" },
    ],
    Return_on_Equity: [
      { value: "year", label: "year" },
      { value: "Return_on_Equity", label: "return_on_Equity" },
      { value: "F51", label: "Schedule G3 Line 029 Net income" },
      { value: "F70", label: "Schedule G Line 059 Total fund balances" },
    ],
    Operating_Margin: [
      { value: "year", label: "year" },
      { value: "Operating_Margin", label: "Operating_Margin" },
      {
        value: "F33",
        label: "Schedule G3 Line 008 Revenue from telephone service",
      },
      { value: "F47", label: "Schedule G3 Line 022 Rental of hospital space" },
      { value: "F49", label: "Schedule G3 Line 024 Other" },
      { value: "F30", label: "Schedule G3 Line 004 Operating expenses" },
      {
        value: "F29",
        label: "Schedule G3 Line 003 Net patient service revenue",
      },
    ],
    Current_Ratio: [
      { value: "year", label: "year" },
      { value: "Current_Ratio", label: "Current_Ratio" },
      { value: "F56", label: "Schedule G Line 011 Current assets" },
      { value: "F68", label: "Schedule G Line 045 Current liabilities" },
    ],
    Days_Cash_on_Hand: [
      { value: "year", label: "year" },
      { value: "Days_Cash_on_Hand", label: "Days_Cash_on_Hand" },
      { value: "F52", label: "Schedule G Line 001 Cash on hand or in banks" },
      { value: "F53", label: "Schedule G Line 002 Temporary investments" },
      { value: "F65", label: "Schedule G Line 031 Investments" },
      { value: "F13", label: "Schedule A Column 3 Line 200 Total expenses" },
      {
        value: "F14",
        label:
          "Schedule A Column 002 Line 001 Capital Related Costs- Building and Fixtures",
      },
      {
        value: "F15",
        label:
          "Schedule A Column 002 Line 002 Capital Related Costs- Movable Equipment",
      },
      {
        value: "F16",
        label: "Schedule A Column 002 Line 003 Other Capital Related Costs",
      },
      { value: "F78", label: "Days in period" },
    ],
    Days_In_Net_Accounts_Receivable: [
      { value: "year", label: "year" },
      {
        value: "Days_In_Net_Accounts_Receivable",
        label: "Days_In_Net_Accounts_Receivable",
      },
      { value: "F54", label: "Schedule G Line 004 Gross accounts receivable" },
      {
        value: "F55",
        label: "Schedule G Line 006 Allowances for uncollectibles",
      },
      {
        value: "F29",
        label: "Schedule G3 Line 003 Net patient service revenue",
      },
      { value: "F78", label: "Days in period" },
    ],
    Days_In_Gross_AR: [
      { value: "year", label: "year" },
      {
        value: "Days_In_Gross_AR",
        label: "Days_In_Gross_AR",
      },
      { value: "F54", label: "Schedule G Line 004 Gross accounts receivable" },
      { value: "F27", label: "Schedule G3 Line 001 Total patient revenues" },
      { value: "F78", label: "Days in period" },
    ],
    Equity_Financing: [
      { value: "year", label: "year" },
      { value: "Equity_Financing", label: "Equity_Financing" },
      { value: "F70", label: "Schedule G Line 059 Total fund balances" },
      { value: "F66", label: "Schedule G Line 036 Total assets" },
    ],

    Debt_Service_Coverage: [
      { value: "year", label: "year" },
      { value: "Debt_Service_Coverage", label: "Debt_Service_Coverage" },
      { value: "F51", label: "Schedule G3 Line 029 Net income" },
      {
        value: "F14",
        label:
          "Schedule A Column 002 Line 001 Capital Related Costs- Building and Fixtures",
      },
      {
        value: "F15",
        label:
          "Schedule A Column 002 Line 002 Capital Related Costs- Movable Equipment",
      },
      {
        value: "F16",
        label: "Schedule A Column 002 Line 003 Other Capital Related Costs",
      },
      {
        value: "F11",
        label: "Schedule A Column 002 Line 113 Interest expense",
      },
      {
        value: "F67",
        label: "Schedule G Line 040 Notes and loans payable (Short-term)",
      },
      { value: "F78", label: "Days in period" },
    ],
    Long_term_Debt_to_Capitalizatio: [
      { value: "year", label: "year" },
      {
        value: "Long_term_Debt_to_Capitalizatio",
        label: "Long_term_Debt_to_Capitalization",
      },
      {
        value: "F67",
        label: "Schedule G Line 040 Notes and loans payable(Short - term)",
      },
      {
        value: "F69",
        label: "Schedule G Line 050 Total long - term liabilities",
      },
      { value: "F70", label: "Schedule G Line 059 Total fund balances" },
    ],
    OP_Revenues_to_Total_Revenues: [
      { value: "year", label: "year" },
      {
        value: "OP_Revenues_to_Total_Revenues",
        label: "OP_Revenues_to_Total_Revenues",
      },
      {
        value: "F24",
        label: "Schedule G2 Column 002 Line 028 Outpatient revenues",
      },
      {
        value: "F25",
        label: "Schedule G2 Column 003 Line 028 Total patient revenues",
      },
    ],
    Patient_Deductions: [
      { value: "year", label: "year" },
      { value: "Patient_Deductions", label: "Patient_Deductions" },
      { value: "F28", label: "Schedule G3 Line 002 Allowances and discounts" },
      { value: "F27", label: "Schedule G3 Line 001 Total patient revenues" },
    ],
    Medicare_InPatient_Payer_Mix: [
      { value: "year", label: "year" },
      {
        value: "Medicare_InPatient_Payer_Mix",
        label: "Medicare_InPatient_Payer_Mix",
      },
      {
        value: "F76",
        label: "Schedule S3 Part 1 Column 006 Line 014 Medicare inpatient days",
      },
      {
        value: "F75",
        label: "Schedule S3 Part 1 Column 008 Line 014 Inpatient days",
      },
      {
        value: "F74",
        label: "Schedule S3 Part 1 Column 008 Line 013 Nursery days",
      },
      {
        value: "F73",
        label: "Schedule S3 Part 1 Column 008 Line 006 NF swing - bed days",
      },
    ],
    Hospital_Medicare_OP_Payer_Mix: [
      { value: "year", label: "year" },
      {
        value: "Hospital_Medicare_OP_Payer_Mix",
        label: "Hospital_Medicare_OP_Payer_Mix",
      },
      {
        value: "F19",
        label:
          "Schedule D Part V Column 2 thru 4 Line 202 Medicare outpatient charges",
      },
      {
        value: "F17",
        label: "Schedule C1 Column 007 Line 200 Total outpatient charges",
      },
      {
        value: "F18",
        label:
          "Schedule C1 Column 007 Line 88 Line 117 Non - CAH outpatient charges",
      },
    ],

    Hsp_MCare_OP_Cost_to_Charge: [
      { value: "year", label: "year" },
      {
        value: "Hsp_MCare_OP_Cost_to_Charge",
        label: "Hsp_MCare_OP_Cost_to_Charge",
      },
      {
        value: "F20",
        label:
          "Schedule D Part V Column 5 thru 7 Line 202 Medicare outpatient costs",
      },
      {
        value: "F19",
        label:
          "Schedule D Part V Column 2 thru 4 Line 202 Medicare outpatient charges",
      },
    ],
    Salaries_to_Net_Patient_Revenue: [
      { value: "year", label: "year" },
      {
        value: "Salaries_to_Net_Patient_Revenue",
        label: "Salaries_to_Net_Patient_Revenue",
      },
      { value: "F12", label: "Schedule A Column 001 Line 200 Total salaries" },
      {
        value: "F29",
        label: "Schedule G3 Line 003 Net patient service revenue",
      },
    ],

    Average_Age_of_Plant: [
      { value: "year", label: "year" },
      { value: "Average_Age_of_Plant", label: "Average_Age_of_Plant" },
      {
        value: "F57",
        label:
          "Schedule G Line 014 Accumulated depreciation (Land improvements)",
      },
      {
        value: "F64",
        label:
          "Schedule G Line 028 Accumulated depreciation (HIT designated assets)",
      },
      {
        value: "F14",
        label:
          "Schedule A Column 002 Line 001 Capital Related Costs- Building and Fixtures",
      },
      {
        value: "F15",
        label:
          "Schedule A Column 002 Line 002 Capital Related Costs- Movable Equipment",
      },
      {
        value: "F16",
        label: "Schedule A Column 002 Line 003 Other Capital Related Costs",
      },
      { value: "F78", label: "Days in period" },
    ],

    FTEs_per_Adjusted_Occupied_Bed: [
      { value: "year", label: "year" },
      {
        value: "FTEs_per_Adjusted_Occupied_Bed",
        label: "FTEs_per_Adjusted_Occupied_Bed",
      },
      { value: "F77", label: "Schedule S3 Part 1 Column 010 Line 027 FTEs" },
      {
        value: "F75",
        label: "Schedule S3 Part 1 Column 008 Line 014 Inpatient days",
      },
      {
        value: "F73",
        label: "Schedule S3 Part 1 Column 008 Line 006 NF swing-bed days",
      },
      {
        value: "F74",
        label: "Schedule S3 Part 1 Column 008 Line 013 Nursery days",
      },
      {
        value: "F25",
        label: "Schedule G2 Column 003 Line 028 Total patient revenues",
      },
      {
        value: "F26",
        label: "Schedule G2 Column 001 Line 028 Inpatient revenues",
      },
      {
        value: "F22",
        label: "Schedule G2 Column 001 Line 008 Nursing facility revenue",
      },
      {
        value: "F23",
        label: "Schedule G2 Column 001 Line 009 Other long-term care",
      },
      { value: "F78", label: "Days in period" },
    ],
    Average_Salary_per_FTE: [
      { value: "year", label: "year" },
      { value: "Average_Salary_per_FTE", label: "Average_Salary_per_FTE" },
      { value: "F12", label: "Schedule A Column 001 Line 200 Total salaries" },
      { value: "F77", label: "Schedule S3 Part 1 Column 010 Line 027 FTEs" },
    ],

    Avg_Daily_Census_Swing_SNF_Beds:	[

      { value: "year", label: "year" },
      { value: "Avg_Daily_Census_Swing_SNF_Beds", label: "Avg_Daily_Census_Swing_SNF_Beds" },
      { value: "F72", label: "Schedule S3 Part 1 Column 008 Line 005 SNF swing-bed days" },
      { value: "F78", label: "Days in period" },
    ],

    Average_Daily_Census_Acute_Beds: [	
{ value: "year", label: "year" },
{ value: "Average_Daily_Census_Acute_Beds", label: "Average_Daily_Census_Acute_Beds" },
{ value: "F75", label: "Schedule S3 Part 1 Column 008 Line 014 Inpatient days" },
{ value: "F74", label: "Schedule S3 Part 1 Column 008 Line 013 Nursery days" },
{ value: "F73", label: "Schedule S3 Part 1 Column 008 Line 006 NF swing-bed days" },
  { value: "F72", label: "Schedule S3 Part 1 Column 008 Line 005 SNF swing-bed days" },
]


  },

  statsOptions: [
    {
      value: "Total_Margin",
      label: "Total_Margin",
      data: [
        { value: "F51", label: "Schedule G3 Line 029 Net income" },
        {
          value: "F29",
          label: "Schedule G3 Line 003 Net patient service revenue",
        },
        { label: "Schedule G3 Line 025 Total other income", value: "F50" },
      ],
    },
    { value: "Cash_Flow_Margin", label: "Cash_Flow_Margin" },
    { value: "Return_on_Equity", label: "Return_on_Equity" },
    { value: "Operating_Margin", label: "Operating_Margin" },
    { value: "Current_Ratio", label: "Current_Ratio" },
    { value: "Days_Cash_on_Hand", label: "Days_Cash_on_Hand" },
    {
      value: "Days_In_Net_Accounts_Receivable",
      label: "Days_In_Net_Accounts_Receivable",
    },
    {
      value: 'Days_In_Gross_AR',
      label: 'Days_In_Gross_Account_Receivable'
    },
    { value: "Equity_Financing", label: "Equity_Financing" },
    { value: "Debt_Service_Coverage", label: "Debt_Service_Coverage" },
    {
      value: "Long_term_Debt_to_Capitalizatio",
      label: "Long_term_Debt_to_Capitalizatio",
    },
    {
      value: "OP_Revenues_to_Total_Revenues",
      label: "OP_Revenues_to_Total_Revenues",
    },
    {
      value: "Patient_Deductions",
      label: "Patient_Deductions",
    },
    {
      value: "Medicare_InPatient_Payer_Mix",
      label: "Medicare_InPatient_Payer_Mix",
    },
    {
      value: "Hospital_Medicare_OP_Payer_Mix",
      label: "Hospital_Medicare_OP_Payer_Mix",
    },
    {
      value: "Hsp_MCare_OP_Cost_to_Charge",
      label: "Hsp_MCare_OP_Cost_to_Charge",
    },
    {
      value: "Salaries_to_Net_Patient_Revenue",
      label: "Salaries_to_Net_Patient_Revenue",
    },
    { value: "Average_Age_of_Plant", label: "Average_Age_of_Plant" },
    {
      value: "FTEs_per_Adjusted_Occupied_Bed",
      label: "FTEs_per_Adjusted_Occupied_Bed",
    },
    { value: "Average_Salary_per_FTE", label: "Average_Salary_per_FTE" },
    {
      value: "Avg_Daily_Census_Swing_SNF_Beds",
      label: "Avg_Daily_Census_Swing_SNF_Beds",
    },
    {
      value: "Average_Daily_Census_Acute_Beds",
      label: "Average_Daily_Census_Acute_Beds",
    },
  ],
};
